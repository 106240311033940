import React, {  useState } from "react";
import { Link } from "react-router-dom";

const Switcher = () => {
  const [state, setState] = useState({
    isSwitchToggle: false,
  });

  const toggleThem = () => {
    if (document.body.getAttribute("data-bs-theme") === "light") {
      document.body.setAttribute("data-bs-theme", "dark");
    } else {
      document.body.setAttribute("data-bs-theme", "light");
    }
  };

  const setTheme = (color) => {
    document.getElementById("colorTheme").href =
      "assets/css/colors/" + color + ".css";
    toggleSwitcher();
  };

  const toggleSwitcher = () => {
    setState({ isSwitchToggle: !state.isSwitchToggle });
  };

  return (
    <React.Fragment>
      <div
        id="style-switcher"
        style={{ left: state.isSwitchToggle ? "0px" : "-168px" }}
      >
        <div>
          <h3>Select your color</h3>
          <ul className="pattern">
            <li>
              <Link
                className="color1"
                onClick={() => setTheme("red")}
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                className="color2"
                onClick={() => setTheme("green")}
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                className="color3"
                onClick={() => setTheme("pink")}
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                className="color4"
                onClick={() => setTheme("cyan")}
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                className="color5"
                onClick={() => setTheme("purple")}
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                className="color6"
                onClick={() => setTheme("blue")}
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                className="color7"
                onClick={() => setTheme("orange")}
                to="#"
              ></Link>
            </li>
            <li>
              <Link
                className="color8"
                onClick={() => setTheme("yellow")}
                to="#"
              ></Link>
            </li>
          </ul>
        </div>
        <div className="bottom">
          {/* <Link
            to="#"
            id="mode"
            className="mode-btn text-white"
            onClick={toggleThem}
          >
            <i className="mdi mdi-white-balance-sunny mode-light align-middle"></i>
            <i className="mdi mdi-weather-sunny mode-dark align-middle"></i>
          </Link> */}
          {/* <Link to="#" onClick={toggleSwitcher} className="settings">
            <i className="mdi mdi-cog mdi-spin"></i>
          </Link> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Switcher;
