import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

const Process = ({ sectionClass }) => {
  const [state] = useState({
    services1: [
      {
        icon: "pe-7s-diamond",
        title: "Digital Design",
        desc: "Some quick example text to build on the card title and make up the bulk of the card's content. Moltin gives you the platform.",
      },
      {
        icon: "pe-7s-display2",
        title: "Unlimited Colors",
        desc: "Credibly brand standards compliant users without extensible services. Anibh euismod tincidunt ut laoreet.",
      },
      {
        icon: "pe-7s-piggy",
        title: "Strategy Solutions",
        desc: "Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean necessary regelialia.",
      },
    ],
    services2: [
      {
        icon: "pe-7s-science",
        title: "Awesome Support",
        desc: "It is a paradisematic country, in which roasted parts of sentences fly into your mouth leave for the far World.",
      },
      {
        icon: "pe-7s-news-paper",
        title: "Truly Multipurpose",
        desc: "Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic.",
      },
      {
        icon: "pe-7s-plane",
        title: "Easy to customize",
        desc: "Question Marks and devious Semikoli, but the Little Blind Text didn’t listen. She packed her seven versalia.",
      },
    ],
  });

  return (
    <React.Fragment>
      <section className={"section " + sectionClass} id="services">
        <Container>
        <Row className="justify-content-center">
                <Col lg={10} className="">
          {/* section title */}
          <div style={{display:"flex", width:"100%", justifyContent:"center",alignItems:"center",marginBottom:"2rem"}}>
          <img src="../assets/images/braveTalents-removebg-preview.png" alt = "Remove " style={{width:"200px", height:"70px"}} />
          </div>
          <SectionTitle
            title="Fund your family's american dream"
            desc=""
          />
            <p>
            Did you know that the Number 1 challenge prospective<b> EB5 investors and high-net-worth families</b> face has to do with funds? Sometimes, you have issues with trapped capital in underutilized assets, sometimes your funds are already locked-in in other investments, sometimes you just need substantial time to pull funds from several sources, sometimes the issue is about source and path of funds which must be painstakingly explained to the USCIS…sometimes, the issue is unique to you and your family.

            </p>
            <p>
    

Because as we often say in our Multi-Family Office business, "no two families are ever the same". Therefore, working with diverse investors and families, we know these things and we can help.

            </p>
            <p>
    



Secure your <b>FREE</b> initial consultation with our experienced EB5 Advisors, Attorneys and approved Regional Centers to find the best EB5 funding plan fit for you and your family.
            </p>
        
</Col>
</Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Process;
