import React, { useState } from "react";
import { Link } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { Formik, Field, ErrorMessage , Form} from "formik";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Switcher from "../../components/Footer/Switcher";
import { enqueueSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';
const Signup = () => {
  const [state] = useState({
    firstName: "",
    lastName: "",
    occupation: "",
    familySize: "",
    netWorth: "",
    monthlyIncome: "",
    ebJourney: "",
    salutation: "",
    email: "",
    phoneNo: "",
    city: "",
  });
  const [loading, setLoading] = useState(false);
  return (
    <React.Fragment>
      {/* <div className="account-home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="mdi mdi-home h1"></i>
        </Link>
      </div> */}

      <section className="bg-account-pages mb-5">
        <div className="display-table">
          <div className="display-table-cell " style={{verticalAlign:"top"}}>
            <Container>
              <Row className="justify-content-center">
                <Col lg={10}>
                  <Card className="account-card border-0">
                    <CardBody style={{padding:"0px"}}>
                      
                      <div className="">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            firstName: state.firstName || "",
                            lastName: state.lastName || "",
                            occupation: state.occupation || "",
                            familySize: state.familySize || "",
                            netWorth: state.netWorth || "",
                            monthlyIncome: state.monthlyIncome || "",
                            ebJourney: state.ebJourney || "",
                            salutation: state.salutation || "",
                            email: state.email || "",
                            phoneNo: state.phoneNo || "",
                            city: state.city || "",
                          }}
                          validationSchema={Yup.object().shape({
                            firstName: Yup.string().required(
                              "Please Enter Your First Name"
                            ),
                            email: Yup.string().required(
                              "Please Enter Your email"
                            ),
                            phoneNo: Yup.string().required(
                              "Please Enter Your phoneNo"
                            ),
                          })}
                          onSubmit={async (values) => {
                            try {
                              setLoading(true);
                              // Send registration data to the backend
                              const response = await fetch('https://braveiconsglobal.com/node/api/register', {
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(values),
                              });
                
                              if (response.ok) {
                                enqueueSnackbar(`See you at the event`, {
                                  variant: "success",
                                });
                                console.log('User registered successfully');
                                // You can redirect to a success page or perform other actions here
                              } else {
                                enqueueSnackbar(`User already exists`, {
                                  variant: "error",
                                });
                              }
                              setLoading(false);
                            } catch (error) {
                              console.error('Error during registration:', error);
                            }
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form>
                              <Row className="mb-3  justify-content-between "> 
                              <Col lg={7}>
 <div className="mb-2">
                               <div className="mb-1 d-flex w-100 g-10 justify-content-between">
 <Field
                                  name="firstName"
                                  type="firstName"
                                  placeholder="First Name"
                                  className={
                                    "form-control" +
                                    (errors.firstName && touched.firstName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ width:"48%"}}
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
 <Field
                                  name="lastName"
                                  type="lastName"
                                  placeholder="Last Name"
                                  className={
                                    "form-control" +
                                    (errors.lastName && touched.lastName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{ width:"48%"}}
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                               </div>
                               
                                <Field
                                  name="occupation"
                                  type="occupation"
                                  placeholder="Occupation"
                                  className={
                                    "form-control" +
                                    (errors.occupation && touched.occupation
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="occupation"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                <Field
                                  name="familySize"
                                  type="familySize"
                                  placeholder="Family Size"
                                  className={
                                    "form-control" +
                                    (errors.familySize && touched.familySize
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="familySize"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                <Field
                                  name="netWorth"
                                  type="netWorth"
                                  placeholder="Annual Net Worth"
                                  className={
                                    "form-control" +
                                    (errors.netWorth && touched.netWorth
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="netWorth"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                <Field
                                  name="monthlyIncome"
                                  type="monthlyIncome"
                                  placeholder="Monthly Income"
                                  className={
                                    "form-control" +
                                    (errors.monthlyIncome && touched.monthlyIncome
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="monthlyIncome"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                <Field
                                  name="ebJourney"
                                  type="ebJourney"
                                  placeholder="How soon do you plan to start your EB5 journey?"
                                  className={
                                    "form-control" +
                                    (errors.ebJourney && touched.ebJourney
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="ebJourney"
                                  component="div"
                                  className="invalid-feedback"
                                />
                            
                               
                              </div>
                              </Col>
                              <Col lg={5}>  
                              <Field
                                  name="salutation"
                                  type="salutation"
                                  placeholder="Mr/Mrs/Miss"
                                  className={
                                    "form-control" +
                                    (errors.salutation && touched.salutation
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="salutation"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              <Field
                                  name="email"
                                  type="email"
                                  placeholder="Email"
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                       
                                <Field
                                  name="phoneNo"
                                  type="phoneNo"
                                  placeholder="Mobile"
                                  className={
                                    "form-control" +
                                    (errors.phoneNo && touched.phoneNo
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="phoneNo"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                 <Field
                                  name="city"
                                  type="city"
                                  placeholder="City you live in"
                                  className={
                                    "form-control" +
                                    (errors.city && touched.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              <div className="d-grid h-lg-25">

                                
                                <button
                                  type="submit"
                                  className="btn btn-primary "
                                  disabled={loading} // Disable the button when loading
                                  >
                                    {loading ? (
                                      <CircularProgress size={24} color="inherit" /> // Show spinner when loading
                                    ) : (
                                      'Register'
                                    )}
                                </button>
                              </div>
                              
                              
                              </Col>
                                
                             
                              </Row>
                           
                             

                             

                            
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                  <p className="pt-4">
    



<b>Note:</b> Investors family are treated on a case by case basis with full family financial history disclosed. <br/>
3rd party financing may atrract fees by our partners and investment bankers. Terms and conditions apply
                </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        {/* color switcher */}
        <Switcher />
      </section>
    </React.Fragment>
  );
};

export default Signup;
