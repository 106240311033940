import React from "react";

//Importing Modal
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

const ModalSection = ({ channel, isOpen, videoId, closeModal }) => {


  return (
    <React.Fragment>
      <ModalVideo
        channel={channel}
        isOpen={isOpen}
        videoId={videoId}
        onClose={closeModal}
      />
    </React.Fragment>
  );
};

export default ModalSection;
