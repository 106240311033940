import React, {  useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

//Importing Modal
import ModalSection from "../../components/common/ModalSection";

const Section = () => {
  const [state, setState] = useState({
    isOpen: false,
  });

  const callModal = () => {
    setState({ isOpen: true });
  };

  const closeModal = () => {
    setState({ isOpen: false });
  };

  //particales
  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <React.Fragment>
      <section className="section bg-home vh-100" id="home">
        <div className="bg-overlay">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              style: {
                position: "absolute",
              },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 100,
                    duration: 9,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.3,
                  width: 2,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 2,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 900,
                  },
                  value: 100,
                },
                opacity: {
                  value: 0.4,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />
        </div>

        <div className="">
          <div className="">
            <Container className="slidero">
              <Row>
                <Col
                  lg={{ size: 8, offset: 2 }}
                  className="text-white text-center"
                >
                  <h1 className="home-title">
                    We help startups launch their products
                  </h1>
                  <p className="pt-3 home-desc">
                    Etiam sed.Interdum consequat proin vestibulum class at a
                    euismod mus luctus quam.Lorem ipsum dolor sit amet,
                    consectetur adipisicing eli.
                  </p>
                  <p className="play-shadow mmt-4">
                    <Link
                      to="#"
                      onClick={callModal}
                      className="play-btn video-play-icon"
                    >
                      <i className="mdi mdi-play text-center"></i>
                    </Link>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* Render ModalSection Component for Modal */}
        <ModalSection
          channel="vimeo"
          videoId="99025203"
          closeModal={closeModal}
          isOpen={state.isOpen}
        />
      </section>
    </React.Fragment>
  );
};

export default Section;
